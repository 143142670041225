import Box from "@ui/Box"
import Icon from "@ui/Icon/Icon"
import styled from "@emotion/styled"
import Text from "@ui/Text/Text"
import { rem, shade } from "polished"
import React from "react"
import { variant } from "styled-system"

import { useAccordion } from "."

export const HeadingComponent = styled.button<{ variant: string }>`
  --bg: ${(props) => props.theme.colors.rhino};
  --bg-hover: ${(props) => shade(0.1, props.theme.colors.rhino)};
  --bg-dark: ${(props) => shade(0.25, props.theme.colors.rhino)};

  width: 100%;
  border: 0;
  display: flex;
  align-items: center;
  padding: ${(props) => props.theme.space.lg};
  margin-top: ${(props) => props.theme.space.lg};

  color: #fff;
  cursor: pointer;
  text-decoration: none;

  transition: all 0.25s ease-in-out;
  position: relative;

  &:first-of-type {
    margin-top: 0;
  }

  :focus {
    outline: 0;
  }

  &::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border: 12px solid #fff;
    border-bottom-color: #fff !important;
    border-right-color: #fff !important;
  }

  &[aria-expanded="true"] svg {
    transform: rotate(90deg);
  }

  background-color: var(--bg);

  :hover,
  :focus {
    background-color: var(--bg-hover);
  }

  :after {
    border-color: var(--bg-dark);
  }

  ${(props) =>
    variant({
      variants: {
        preparation: {
          "--bg": props.theme.colors.tangerine,
          "--bg-hover": shade(0.1, props.theme.colors.tangerine),
          "--bg-dark": shade(0.25, props.theme.colors.tangerine),
        },
        vision: {
          "--bg": props.theme.colors.sienna,
          "--bg-hover": shade(0.1, props.theme.colors.sienna),
          "--bg-dark": shade(0.25, props.theme.colors.sienna),
        },
        "context-analysis": {
          "--bg": props.theme.colors.blossom,
          "--bg-hover": shade(0.1, props.theme.colors.blossom),
          "--bg-dark": shade(0.25, props.theme.colors.blossom),
        },
        "future-analysis": {
          "--bg": props.theme.colors.sky,
          "--bg-hover": shade(0.1, props.theme.colors.sky),
          "--bg-dark": shade(0.25, props.theme.colors.sky),
        },
        "strategic-plan": {
          "--bg": props.theme.colors.jade,
          "--bg-hover": shade(0.1, props.theme.colors.jade),
          "--bg-dark": shade(0.25, props.theme.colors.jade),
        },
        "operational-plan": {
          "--bg": props.theme.colors.acapulco,
          "--bg-hover": shade(0.1, props.theme.colors.acapulco),
          "--bg-dark": shade(0.25, props.theme.colors.acapulco),
        },
      },
    })};
`

const ModuleHeading = styled(Text)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 40rem) {
    flex-direction: row;
    align-items: center;
  }
`

ModuleHeading.defaultProps = {
  as: "div",
  fontSize: "lg",
}

const ModuleName = styled.span`
  font-weight: 600;
`

const Completed = styled.span`
  text-transform: uppercase;
  font-size: ${rem("12px")};
  font-weight: bold;
  margin-left: auto;
  margin-right: 1rem;

  background: var(--bg-dark);
  padding: 2px 4px;
  border-radius: 3px;
`

const ModuleIndex = styled.span`
  white-space: nowrap;
  margin-right: 0.75rem;
`

interface AccordionItemProps {
  id: string
  title: string
  number: number
  completed: boolean
  children: React.ReactNode
}

const AccordionItem = ({
  id,
  title,
  number,
  completed,
  children,
}: AccordionItemProps) => {
  const { selected, setSelected } = useAccordion()

  const expanded = selected === id

  return (
    <>
      <HeadingComponent
        id={id}
        variant={id}
        aria-expanded={expanded}
        onClick={() =>
          setSelected((prevSelection) => (prevSelection === id ? null : id))
        }
      >
        <Icon name="chevronRight" size="24px" mr="2xs" />
        <ModuleHeading>
          <ModuleIndex>Module {number}:</ModuleIndex>
          <ModuleName>{title}</ModuleName>
          {completed && <Completed>completed</Completed>}
        </ModuleHeading>
      </HeadingComponent>
      <Box hidden={!expanded} p="lg" aria-labelledby={id}>
        {children}
      </Box>
    </>
  )
}

export default AccordionItem
