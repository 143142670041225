import Accordion from "@components/accordion"
import AccordionItem from "@components/accordion/accordion-item"
import CenterLayout from "@components/centerlayout"
import Parallax, { ImgTitle, ImgCopyright } from "@components/parallax"
import { MDXProvider } from "@mdx-js/react"
import Box from "@ui/Box"
import Button from "@ui/Button/Button"
import Heading from "@ui/Heading/Heading"
import Icon from "@ui/Icon/Icon"
import Link, { AppLink } from "@ui/Link/Link"
import styled from "@emotion/styled"
import Text from "@ui/Text/Text"
import { graphql, Link as GatsbyLink } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { rem, rgba } from "polished"
import React from "react"
import * as R from "ramda"
import { DividerIllustration } from "../components/DividerIllustration"
import Layout from "../components/layout"
import SEO from "../components/seo"
import useModuleCompleted from "@components/useModuleCompleted"
import DividerLine from "@components/DividerLine"

const ModuleLink = styled(GatsbyLink)`
  position: relative;
  margin-top: ${(props) => props.theme.space.lg};
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: none;

  &:before {
    content: "";
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
    border-bottom: 6px solid ${(props) => rgba(props.theme.colors.rhino, 0.2)};
    transition: all 0.2s ease-in-out;
  }

  &,
  &:visited {
    color: inherit;
  }

  &:focus,
    outline: 0;
  }

  &:focus:before,
  &:hover:before {
    border-bottom-color: ${(props) => rgba(props.theme.colors.rhino, 0.3)};
  }
`

const EmailLink = styled.a`
  color: inherit;
  text-transform: uppercase;
  font-size: ${(props) => props.theme.fontSizes.sm};
  font-weight: bold;
  margin: ${(props) => props.theme.space.xl} auto 0;

  text-decoration: none;
  display: inline-block;
  border-bottom: 1px solid transparent;

  span {
    display: block;
    transition: opacity 0.2s ease-in-out;
  }

  &:focus {
    outline: 0;
    border-color: #fff;
  }

  &:hover span {
    opacity: 0.6;
  }
`

interface Props {
  data: {
    topModules: {
      edges: {
        node: {
          body: string
          frontmatter: {
            title: string
            id: string
            order: number
          }
        }
      }[]
    }
    firstSteps: {
      edges: {
        node: {
          body: string
          frontmatter: {
            id: string
          }
        }
      }[]
    }
    nextGeneration: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData
      }
    }
  }
}

const WhiteSpan = styled.span`
  color: #fff;
`

const extractFirstStep = R.pipe(
  R.pathOr([], ["group"]),
  R.map((group: any) => [
    group.fieldValue as string,
    R.pipe(
      R.pathOr([], ["edges"]),
      R.map(R.path(["node", "frontmatter", "id"]))
    )(group)[0],
  ]) as any,
  R.fromPairs
)

const IndexPage = ({ data }: Props) => {
  const firstSteps = extractFirstStep(data.firstSteps) as {
    [module: string]: string
  }
  const isModuleCompleted = useModuleCompleted()

  return (
    <MDXProvider components={{}}>
      <Layout>
        <DividerIllustration loading="eager" />
        <SEO title="Home" />
        <Box py="5xl" backgroundColor="rhino" color="aquamarine">
          <CenterLayout>
            <Text textAlign="center" fontFamily="secondary" fontSize="2xl">
              <WhiteSpan>
                Guide for Integrated Planning in Africa - mainstreaming the
                SDGs, Agenda 2063, Climate Change and Disaster Risk Reduction in
                National Development Plans
              </WhiteSpan>
            </Text>
            <Text
              textAlign="center"
              fontFamily="secondary"
              fontSize="lg"
              mt="xs"
            >
              Produced by the United Nations Development Programme, the African
              Development Bank, the United Nations Economic Commission for
              Africa and the African Union Development Agency-NEPAD
            </Text>
          </CenterLayout>
        </Box>
        <Box py="5xl">
          <CenterLayout maxWidth={rem("640px")}>
            <Text textAlign="center">
              The purpose of this Integrated Planning Guide is to support you as
              a staff member of a national government in charge of developing a
              National Development Plan, as well as relevant stakeholders who
              are engaged in the integrated planning process. The Guide provides
              tools, help, step-by-step guidance and cases that demonstrate good
              practices.
            </Text>
            <Text textAlign="center" mt="md">
              Before you start, please read the{" "}
              <AppLink to="/how-to">How to use this Guide</AppLink>,{" "}
              <AppLink to="/relevant-tools">Relevant tools</AppLink> and the{" "}
              <Link
                href="https://iprt.uneca.org"
                rel="noopener"
                target="_blank"
              >
                Integrated Planning and Reporting Toolkit (IPRT)
              </Link>{" "}
              - a relevant set of integrated tools to assess and visualize the
              alignment of National Development Plans with Agenda 2030 and
              Agenda 2063 and assess progress towards achieving them.
            </Text>
          </CenterLayout>
        </Box>
        <CenterLayout>
          <Accordion>
            {data.topModules.edges.map(
              (
                {
                  node: {
                    body,
                    frontmatter: { id, title },
                  },
                },
                index
              ) => (
                <AccordionItem
                  key={id}
                  id={id}
                  title={title}
                  number={index + 1}
                  completed={isModuleCompleted(id)}
                >
                  <MDXRenderer>{body}</MDXRenderer>
                  <ModuleLink to={`/modules/${id}/${firstSteps[id]}`}>
                    Go to module <Icon name="chevronRight" size="24px" />
                  </ModuleLink>
                </AccordionItem>
              )
            )}
          </Accordion>
        </CenterLayout>
        <CenterLayout my="4xl">
          <DividerLine />
          <CenterLayout textAlign="center" maxWidth="smallBlock" mt="4xl">
            <Heading
              size="2xl"
              mb="md"
              as="h3"
              fontFamily="secondary"
              color="rhino"
            >
              A PDF version
            </Heading>
            <Text>
              The Guide for Integrated Planning in Africa is also available as a
              PDF version. <br /> Click the button below to download the file.
            </Text>
            <Button
              as="a"
              href="/Guide_for_Integrated_Planning_in_Africa_final.pdf"
              target="_blank"
              variant="primary"
              height="4xl"
              px="xl"
              mt="xl"
              mb="xs"
            >
              <Icon name="print" size="lg" mr="sm" />
              Download a PDF version of the guide
            </Button>
            <Text size="sm" textAlign="center">
              Please consider the environment before printing.
            </Text>
          </CenterLayout>
        </CenterLayout>
        <Parallax
          scaleFactor={1.25}
          backgroundProps={{
            image: data.nextGeneration.childImageSharp.gatsbyImageData,
            alt: "Planning for the next generation",
          }}
        >
          <CenterLayout py={rem("224px")}>
            <ImgTitle>Planning for the next generation</ImgTitle>
            <ImgCopyright>Photography by UNDP</ImgCopyright>
          </CenterLayout>
        </Parallax>
        <Box py="5xl" backgroundColor="acapulco" color="white">
          <CenterLayout maxWidth={rem("736px")} textAlign="center">
            <Text fontFamily="secondary" fontSize="3xl" color="sherpa">
              A living document
            </Text>
            <Text fontSize="md" mt="xs">
              The Integrated Planning Guide in Africa to mainstream the
              Sustainable Development Goals (SDGs), AU Agenda 2063, Nationally
              Determined Contributions (NDCs) and Disaster Risk Reduction (DRR)
              is a living document and will be continuously updated. Additional
              modules covering monitoring and evaluation, budgeting and
              alignment with local plans are forthcoming.
            </Text>
            <EmailLink href="mailto:ipguideafrica@gmail.com">
              <Icon
                name="email"
                size="32px"
                mx="auto"
                mb="xs"
                display="block"
              />
              <span>Email us with feedback</span>
            </EmailLink>
          </CenterLayout>
        </Box>
        <DividerIllustration />
      </Layout>
    </MDXProvider>
  )
}

export const pageQuery = graphql`
  {
    topModules: allMdx(
      sort: { order: ASC, fields: [frontmatter___order] }
      filter: { frontmatter: { kind: { eq: "module" } } }
    ) {
      edges {
        node {
          body
          frontmatter {
            id
            title
            order
          }
        }
      }
    }
    firstSteps: allMdx(
      sort: { order: ASC, fields: [frontmatter___order] }
      filter: { frontmatter: { kind: { eq: "step" } } }
    ) {
      group(field: frontmatter___module, limit: 1) {
        fieldValue
        edges {
          node {
            frontmatter {
              id
            }
          }
        }
      }
    }
    nextGeneration: file(relativePath: { eq: "next_generation.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          transformOptions: { fit: COVER, cropFocus: ATTENTION }
          layout: FULL_WIDTH
        )
      }
    }
  }
`

export default IndexPage
